<template>
    <div>
        <van-nav-bar title="实时视频" left-text="返回" @click-left="$router.go(-1)" left-arrow />
        <div class="name">相机1</div>
        <video width="340" height="240" controls></video>
        <div class="name">相机2</div>
        <video width="340" height="240" controls></video>
    </div>
</template>

<script>
export default {
    created(){
        this.$toast('功能完善中，敬请期待...')
    }
}
</script>

<style lang="scss" scoped>
.name{
    text-align: left;
    padding-left: 4%;
    font-size: .9rem;
    font-weight: bold;
    color: #2F9E73;
    border-left: 6px solid #2F9E73;
    margin: 2% 0;
}
</style>